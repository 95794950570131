import VueRouter from "vue-router";

const routes = [
    {
        path:'/',
        name:'login',
        component:()=>import('../components/Login')
    },
    {
        path:'/Index',
        name:'index',
        component:()=>import('../components/IndexMain'),
        children:[
            {
                path:"/Home",
                name:'home',
                meta:{
                    title:'首页'
                },
                component:()=>import("../components/Main")
            },
            {
                path: '/aizhong',
                name:'address',
                meta:{
                    title:'癌肿种类'
                },
                component:()=>import("../components/aizhong/aizhong"),
            },
            {
                path: '/jiyin',
                name:'address',
                meta:{
                    title:'基因种类'
                },
                component:()=>import("../components/jiyin/jiyin"),
            },
            {
                path: '/daohang',
                name:'address',
                meta:{
                    title:'导航'
                },
                component:()=>import("../components/daohang/daohang"),
            },
            {
                path: '/Address',
                name:'address',
                meta:{
                    title:'研究中心'
                },
                component:()=>import("../components/address/address"),
            },
            {
                path: '/Admin1',
                name:'projectName',
                meta:{
                    title:'项目中心'
                },
                component:()=>import("../components/admin/ProjectYanjiu"),
            },
            {
                path: '/Admin',
                name:'admin',
                meta:{
                    title:'研究中心'
                },
                component:()=>import("../components/admin/YanJiu"),
            },
            {
                path:"/User",
                name:'user',
                meta:{
                    title:'用户管理'
                },
                component:()=>import("../components/user/userManage")
            },
            // {
            //     path:"/Patient",
            //     name:'patient',
            //     meta:{
            //         title:'患者管理'
            //     },
            //     component:()=>import("../components/user/patient")
            // },
            // {
            //     path:"/PatientXian",
            //     name:'patientXian',
            //     meta:{
            //         title:'患者线索'
            //     },
            //     component:()=>import("../components/user/patientXian")
            // },
            // {
            //     path:"/Dakaiyouyue",
            //     name:'dakaiyouyue',
            //     meta:{
            //         title:'大咖有约'
            //     },
            //     component:()=>import("../components/dakaiyouyue/dakaiyouyue")
            // },
            // {
            //     path:"/Wenzhangtuijian",
            //     name:'wenzhangtuijian',
            //     meta:{
            //         title:'文章推荐'
            //     },
            //     component:()=>import("../components/dakaiyouyue/wenzhangtuijian")
            // },
            // {
            //     path:"/Zhaomuketang",
            //     name:'zhaomuketang',
            //     meta:{
            //         title:'招募课堂'
            //     },
            //     component:()=>import("../components/dakaiyouyue/zhaomuketang")
            // },
        ],
    },
]

const router =new VueRouter({
    mode:'history',
    routes// 这里默认是hash模式

})
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to){
    return VueRouterPush.call(this,to).catch(err =>err)
}

export default router